import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import PanelNav from '../../components/PanelNav';
import { ColorIcon, IconographyIcon, TypographyIcon, AnimationIcon } from '../../components/Icon';
import Nice from '@openameba/spindle-ui/Icon/Nice';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="スタイル" mdxType="SEO" />
    <PageTitle title="スタイル" enTitle="Styles" mdxType="PageTitle" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fa0051a120a9fe10a476d0915ea2ba17/68947/styles-index.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fa0051a120a9fe10a476d0915ea2ba17/2aaaf/styles-index.webp 160w", "/static/fa0051a120a9fe10a476d0915ea2ba17/85e47/styles-index.webp 320w", "/static/fa0051a120a9fe10a476d0915ea2ba17/75198/styles-index.webp 640w", "/static/fa0051a120a9fe10a476d0915ea2ba17/691bc/styles-index.webp 960w", "/static/fa0051a120a9fe10a476d0915ea2ba17/223e5/styles-index.webp 1280w", "/static/fa0051a120a9fe10a476d0915ea2ba17/3c2f7/styles-index.webp 1281w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fa0051a120a9fe10a476d0915ea2ba17/69538/styles-index.png 160w", "/static/fa0051a120a9fe10a476d0915ea2ba17/72799/styles-index.png 320w", "/static/fa0051a120a9fe10a476d0915ea2ba17/6af66/styles-index.png 640w", "/static/fa0051a120a9fe10a476d0915ea2ba17/d9199/styles-index.png 960w", "/static/fa0051a120a9fe10a476d0915ea2ba17/21b4d/styles-index.png 1280w", "/static/fa0051a120a9fe10a476d0915ea2ba17/68947/styles-index.png 1281w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fa0051a120a9fe10a476d0915ea2ba17/6af66/styles-index.png",
              "alt": "「Styles」と書かれた吹き出しがスケートボードをする人から出ているイラスト",
              "title": "「Styles」と書かれた吹き出しがスケートボードをする人から出ているイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`スタイルは、Amebaらしさを視覚的に伝えるために必要な要素です。読みやすさを考えたカラーやタイポグラフィのリソースや、どのように使うかのガイドを掲載しています。`}</p>
    <PanelNav title="カラー" enTitle="Color" icon={<ColorIcon aria-hidden="true" mdxType="ColorIcon" />} lead="Amebaの伝統的な緑を基調としたカラーを定義しています。UIに利用されるカラーにはWCAG2.1の達成基準1.4.3に基づき、十分なコントラスト比を担保するように設計されています。" list={[{
      url: "/styles/color/brand",
      name: "ブランドカラー",
      summary: "Amebaらしさの視覚的表現の基調となるカラーパレットです。"
    }, {
      url: "/styles/color/ui",
      name: "UIカラー",
      summary: "Amebaが提供するデジタルコンテンツの中で使用される、RGB色空間に最適化されたカラーパレットです。"
    }, {
      url: "/styles/color/primitive-colors",
      name: "Primitive Colors",
      summary: "ブランドカラーから主要な色相を展開したデザイントークンです。"
    }, {
      url: "/styles/color/theme-colors",
      name: "Theme Colors",
      summary: "Primitive Colorsで定義したカラーをUIで使えるように組み合わせを定義したデザイントークンです。"
    }]} mdxType="PanelNav"></PanelNav>
    <PanelNav title="タイポグラフィ" enTitle="Typography" icon={<TypographyIcon aria-hidden="true" mdxType="TypographyIcon" />} lead="Amebaらしさを言葉で伝えるために、その感情の下地となるタイポグラフィの定義をしています。" list={[{
      url: "/styles/typography/brand",
      name: "ブランドタイポグラフィ",
      summary: "Amebaらしさと、読みやすさ、認識しやすさを意識した書体です。"
    }, {
      url: "/styles/typography/ui",
      name: "UIタイポグラフィ",
      summary: "Amebaが提供するデジタルコンテンツの中で使用される、UI上での読みやすさを考慮した書体選びや表現について定義しています。"
    }]} mdxType="PanelNav"></PanelNav>
    <PanelNav title="アイコン" enTitle="Iconography" icon={<IconographyIcon aria-hidden="true" mdxType="IconographyIcon" />} lead="有機的意匠とシステム的な役割を融合させたAmebaらしいアイコンとして、ブランドタイポグラフィで使用されているAmeba Sansに着想を得て設計しています。" list={[{
      url: "/styles/iconography/anatomy/",
      name: "解剖",
      summary: "Amebaらしいアイコンとしてのコンセプトや原則について定義しています。"
    }, {
      url: "/styles/iconography/icons/",
      name: "一覧",
      summary: "原則に従って作成されたアイコンの一覧です。"
    }, {
      url: "/styles/iconography/developer/",
      name: "開発者向け",
      summary: "アイコンの利用方法についての案内です。"
    }]} mdxType="PanelNav"></PanelNav>
    {
      /* textlint-disable @openameba/ameba/no-synonyms */
    }
    <PanelNav title="イラストレーション" enTitle="Illustration" icon={<Nice aria-hidden="true" mdxType="Nice" />} lead="誰でもAmebaらしいイラストが作成できるように構造がシステム化されています。" list={[{
      url: "/styles/illustration/introduction/",
      name: "イントロダクション",
      summary: "Ameba Illustrationの構造を説明します。"
    }, {
      url: "/styles/illustration/introduction/",
      name: "レギュレーション",
      summary: "Ameba Illustrationを利用する上でのレギュレーションです。"
    }]} mdxType="PanelNav"></PanelNav>
    <PanelNav title="アニメーション" enTitle="Animation" icon={<AnimationIcon aria-hidden="true" mdxType="AnimationIcon" />} lead="アニメーションを定義することで、UIに連続的な動きと物理的な加速度を反映します。UI内の要素の動きに対してこれらを反映することで現実世界との距離を縮め、ユーザーに対して親しみやすさや、よりよい操作感を提供できます。" list={[{
      url: "/styles/animation/property/",
      name: "プロパティ",
      summary: "EasingとDurationの変数のパターンを定義して、それの組合わせをAnimation Typeとしてパッケージングして、それをComponent側に代入し利用します。"
    }, {
      url: "/styles/animation/type/",
      name: "種類",
      summary: "Animation Tokenのバリエーションと、どの動きに対してどのアニメーションを適用するかの一覧です。"
    }]} mdxType="PanelNav"></PanelNav>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      